import revive_payload_client_ebITZosetO from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4RIiSOvUFh from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_h1Y1aDBBmp from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VFwRWjUeTy from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3RChsyVhXg from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GYKA0Emhxd from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_yYKsIU9lqb from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_H1FyhFm7VJ from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_qS3T1IfjBz from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_l7224wtr7tjncdw6qoyrxyvahy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_CwRSvucfCh from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SSfmvuSkPH from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_Bbn4zwNHK9 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.3_sass@1.78.0_terser_6ia23nxh6sy2o7uroj5tbmpmae/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_lyl3QTTsRZ from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.3_sass@1.78.0_terser_6ia23nxh6sy2o7uroj5tbmpmae/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_wzcz6vz1JN from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.3_sass@1.78.0_terser_6ia23nxh6sy2o7uroj5tbmpmae/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ku775Rc9Bz from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_egnxiNVSS6 from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.3_sass@1.78.0_terse_dlfdcivortpns5nwkuxgu6worq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_zoF4yPIBkC from "/app/node_modules/.pnpm/nuxt-socket-io@3.0.13/node_modules/nuxt-socket-io/lib/plugin.js";
import google_recaptcha_rYK9Kziv68 from "/app/plugins/google-recaptcha.ts";
import konva_7dtTaXju5v from "/app/plugins/konva.js";
import mitt_ZNXaeqsgM5 from "/app/plugins/mitt.js";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import sockets_tf4I3c66A4 from "/app/plugins/sockets.js";
import vuesax_IhK6CckThS from "/app/plugins/vuesax.js";
export default [
  revive_payload_client_ebITZosetO,
  unhead_4RIiSOvUFh,
  router_h1Y1aDBBmp,
  payload_client_VFwRWjUeTy,
  navigation_repaint_client_3RChsyVhXg,
  check_outdated_build_client_GYKA0Emhxd,
  view_transitions_client_yYKsIU9lqb,
  chunk_reload_client_H1FyhFm7VJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qS3T1IfjBz,
  plugin_6Ph1iQzNHk,
  switch_locale_path_ssr_CwRSvucfCh,
  i18n_SSfmvuSkPH,
  slideovers_Bbn4zwNHK9,
  modals_lyl3QTTsRZ,
  colors_wzcz6vz1JN,
  plugin_client_ku775Rc9Bz,
  plugin_egnxiNVSS6,
  plugin_zoF4yPIBkC,
  google_recaptcha_rYK9Kziv68,
  konva_7dtTaXju5v,
  mitt_ZNXaeqsgM5,
  sentry_3AyO8nEfhE,
  sockets_tf4I3c66A4,
  vuesax_IhK6CckThS
]